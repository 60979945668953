.header-base {
  background-color: #fff;
  width: 83%;
  height: 100%;
  max-height: 50px;
  box-shadow: 18px 0px 35px 0px rgb(0 0 0 / 2%);
  display: flex;
  align-items: center;
  padding: 0 50px;
  justify-content: right;
}

.header-profileIcon {
  cursor: pointer;
  padding: 25px;
}
