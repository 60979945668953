.editOrder-base {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
  text-align: center;
}

.updateorder-select {
  padding: 5px 7px;
  min-width: 20px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
}
.left-child-input{
  margin: auto 10px;
  font-size: 13px;
}
.input-wrapper{
  margin: 15px auto
}