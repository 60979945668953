.main-header {
  margin-bottom: 25px;
}

.dashboard-topSection {

  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 600px;
}

.dashboard-countCards {
  padding: 20px;
  border-radius: 10px;
  border-width: 0.5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(135deg, #d1e6f5 10%, #0994f8 100%);

}
