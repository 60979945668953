.button-base,
.button-hovered,
.button-disabled,
.button-loading,
.button-parentHovered {
  width: auto;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  /* transition: 300ms; */
  position: relative;
  padding: 0 15px;
  background-size: cover;
}
.button-base:after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  animation: pulse 1s infinite;
  display: none;
}

.button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.button-loading.button-base:after {
  display: block;
}
.button-loading {
  pointer-events: none;
}
.button-loading span {
  display: none;
}
.button-loading:after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
/* .iconWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.iconHolder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  transition: 300ms;
}

.icon {
  composes: iconHolder;
  opacity: 1;
}

.iconHovered {
  composes: iconHolder;
  opacity: 0;
} */
