.sidebar-base {
  position: relative;
  height: 100vh;
  box-shadow: 18px 0px 35px 0px rgb(0 0 0 / 2%);
  width: 100%;
  height: 100%;
  background-color: #fff;
  

}

.sidebar-logoContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  
  padding: 10px;
  width: 100%;
  
}



.sidebar-navLink,
.sidebar-navLink-active {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 20px 50px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
}
.sidebar-navLink-active {
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}
.sidebar-navLink:hover,
.sidebar-navLink-active:hover {
  transform: scale(0.99);
}

.sidebar-navLink-icon {
  margin-right: 10px;
  padding: 5px;
  border-radius: 3px;
  /* background-color: var(--primary-color-light); */
  background-color: #f8f8f8;
  /* box-shadow: rgb(204 204 204 / 26%) 0px 0px 1px 3px; */
}

.sidebar-navLink-active .sidebar-navLink-label {
  color: inherit;
}
.sidebar-navLink-label {
  color: var(--primary-color);
}
