.modalContainer {
  z-index: 100000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}
.modalContainer .modal {
  width: 600px;
  height: auto;
  background-color: transparent;
  /* transform: translateX(200px);
  transform: translateY(200px); */
  transition: all 0.4s ease;
  overflow-y: auto;
}
.modal_content {
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContainer.show {
  visibility: visible;
  opacity: 1;
}
.modalContainer.show .modal {
  transform: translateY(0);
}
