.coupon-topSection {
  width: 300px;
  padding: 20px;
  /* height: 80px; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  cursor: pointer;
}

.coupon-tableAction {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10%;
}
