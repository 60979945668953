.icon-base {
  position: relative;
}

.icon-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
