/* .table-base {
  text-align: left;
  border-radius: 20px;
  width: 100%;
  border-spacing: 0px;
}

.table-head {
  text-transform: capitalize;
}

.table-row {
}

.table-cell {
}
.table-row:not(:last-child) > .table-cell {
  border-bottom: 1px solid #f8f8f8;
} */
table {
  border-spacing: 0px;
  width: 100%;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
  border: solid 1px #efefef;
  /* border: solid 0.5px #ccc; */
}

table th {
  text-transform: capitalize;
}

table tr {
  height: 50px;
}
table tr td,
table th {
  padding: 10px;
  border: solid 0.25px #efefef;
  font-size: 14px;
}
table tr:nth-child(even) {
  background-color: #f5f5f5;
  background-color: #f8f7f9;
}
table thead tr td {
  padding: 10px;
  border: solid 0.25px #efefef;
  font-size: 14px;
  font-family: ProximaNova-Bold;
}
table tr {
  position: relative;
  cursor: pointer;
}
table tbody tr:hover {
  background: #c6d6f8;
  box-shadow: var(--box-shadow);
}
table tbody tr:hover td {
  border-color: #c6d6f8;
}
