.input-base,
.input-focused,
.input-disabled,
.input-hollow {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background: #f3f3f3;
  display: flex;
  transition: 300ms;
}

.input-focused {
  background: #f3f3f3;
}

.input-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.input-hollow {
  background: transparent;
  border: 1px solid #e8edf1;
}
.input-box {
  height: 100%;
  width: 100%;
  border: none;
  padding: 0 20px;
  /* padding: 12px 10px; */
  font-size: 18px;
  color: inherit;
  background: transparent;
  /* font-family: Proxima Nova; */
}
.input-box:focus {
  /* border: none; */
  outline: none;
}
.input-box:focus {
  outline: none;
  /* border: 1px solid var(--primary-color) !important; */
  background-color: var(--primary-color-light) !important;
  color: var(--primary-color);
}

.input-leftChild,
.input-rightChild {
  width: auto;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.inputIconWrapper {
  display: flex;
}
input::placeholder {
  background-color: transparent;
}
