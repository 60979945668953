.base,
.verticallyCenterBase {
  width: 100%;
  height: 100%;
}
.verticallyCenter {
  position: relative;
  width: 100%;
  height: 100%;
}
.verticallyCenterBase {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.fullWidth {
  width: 100%;
}

.imgContainer {
  width: 100%;
  position: relative;
  background: #fff;
}

.imgContainer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
