@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;600;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  --background: #f8f8f8;
  --primary-color: #000;
  --primary-color-light: #efefef;
  --hover-background: #1c1c1c;
  --active-border-color: #c4b2f3;
  --light-border: #fafafb;
  --error-color: #e87d86;
  --error-background: #fef1f2;
  --title-color: #020447;
  --label-color: #676893;
}

.label {
  display: block;
  color: #7e7e7e;
  font-weight: 500;
  margin-bottom: 6px;
}

.inputBox {
  border: 1px solid #ccc;
  border-radius: 7px;
  font-size: 14px;
  width: 100%;
  padding: 10px 12px;
  outline: none;
  color: #6e6e6e;
}
.inputBox:focus {
  background-color: var(--primary-color-light);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.inputBox[type="password"] {
  letter-spacing: 5px;
}

h3 {
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
