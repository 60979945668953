.paginationitem {

  margin: 0;
  padding-top: 10px;
  text-align: center;
  justify-content: center;
}

.paginationitem li {
  display: inline-block;
  text-decoration: none;
  padding: 5px 0;
  margin: 0 2px;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 30px;
  text-align: center;
  position: relative;
}

.paginationitem li.pagination-active {
  background-color: var(--primary-color);
  color: #fff;
}
.paginationitem li:hover:not(.pagination-active) {
  background-color: #ddd;
}

.paginationitem li span.pagination-loading {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginationitem li span.pagination-loading::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
