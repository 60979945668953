.addCoupon-base {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
  min-width: 350px;
}
.row{
  display: flex;
}
.active{
  margin-right: 10px;
  border: solid 1px #2fee7c;
  padding: 4px 20px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #2fee7c;
  color: #0a3f2c;
  transition: all 500ms;
}
.inactive{
  margin-right: 10px;
    border: solid 1px #d8d8d8;
    padding: 4px 20px;
    font-size: 14px;
    border-radius: 3px;
    cursor: pointer;
    color: #0a3f2c;
    transition: all 500ms;
}