.viewCoupon-base {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
  text-align: center;
}

.viewCoupon-header {
  text-align: center;
  margin-bottom: 20px;
}

.viewCoupon-subHeader {
  text-align: left;
  margin: 25px 0 15px;
}
.download-icon-link {
  font-size: 20px;
  text-decoration: none !important;
  font-weight: 900;
  margin-right: 20px;
  cursor: pointer;
}
.download-icon-link a {
  color: inherit;
  text-decoration: inherit;
}